* {
  margin: 0px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
img {
  contain: content;
}
.responsive {
  max-width: 100%;
  height: auto;
}

.hide {
  opacity: 100%;
  transition: 1s;
}
.show {
  opacity: 0%;
  transition: 1s;
}

.claim {
  position: relative;
  top: -120px;
  left: 140px;
  font-size: 50px;
  font-family: "Roboto";
  color: #fff;
text-shadow: 7px 1px 9px rgba(36,27,27,0.59);
}
